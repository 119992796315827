<template>
  <div class="d-flex">
    <div v-for="settingsAction in settingsActions" :key="settingsAction.value">
      <table-action
        :settings-action="settingsAction"
        @editable-handler="openEditForm()"
        @pause-handler="pauseEndpoint()"
        @activate-handler="activateEndpoint()"
        @deletable-handler="$refs.archiveAcceptDialog.ask()"
      />
    </div>
    <confirm-dialog ref="archiveAcceptDialog" @confirm="removeEndpoint()">
      <template #title>
        {{ $t('rtb.endpoints.delete_rtb_endpoint') }}
      </template>
      <template #body>
        {{ $t('rtb.endpoints.delete_rtb_endpoint_msg') }}
      </template>
    </confirm-dialog>
  </div>
</template>

<script>
  import TableAction from '@clickadilla/components/ui/TableAction.vue'
  import rtbEndpointsRepository from '@/services/repositories/rtb-endpoints-repository.js'
  import ConfirmDialog from '@/components/ConfirmDialog.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'RtbActions',
    components: {
      TableAction,
      ConfirmDialog
    },
    props: {
      endpoint: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        pauseIsLoading: false,
        activateIsLoading: false,
        removeIsLoading: false
      }
    },
    computed: {
      settingsActions() {
        return [
          {
            value: 'editable',
            icon: 'mdi-pencil',
            text: this.$t('rtb.endpoints.edit'),
            color: 'info',
            method: 'editable-handler',
            disabled: true
          },
          {
            value: 'pausable',
            icon: 'mdi-pause',
            text: this.$t('rtb.endpoints.pause'),
            color: 'primary',
            method: 'pause-handler',
            isLoading: this.pauseIsLoading,
            disabled: this.endpoint.actions.pausable
          },
          {
            value: 'activable',
            icon: 'mdi-play',
            text: this.$t('rtb.endpoints.activate'),
            color: 'primary',
            method: 'activate-handler',
            isLoading: this.activateIsLoading,
            disabled: this.endpoint.actions.activable
          },
          {
            value: 'deletable',
            icon: 'mdi-delete',
            text: this.$t('rtb.endpoints.archive'),
            color: 'error',
            method: 'deletable-handler',
            isLoading: this.removeIsLoading,
            disabled: this.endpoint.actions.deletable
          }
        ]
      }
    },
    methods: {
      async openEditForm() {
        await this.$router.push({
          name: routeNames.RTB_EDIT_ENDPOINT,
          params: { id: this.endpoint.id }
        })
      },
      async pauseEndpoint() {
        this.pauseIsLoading = true
        try {
          await rtbEndpointsRepository.pause(this.endpoint.id)
        } catch (error) {
          this.$showErrorNotification(error.message)
        }
        this.$emit('update-table')
        this.pauseIsLoading = false
      },
      async activateEndpoint() {
        this.activateIsLoading = true
        try {
          await rtbEndpointsRepository.activate(this.endpoint.id)
        } catch (error) {
          this.$showErrorNotification(error.message)
        }
        this.$emit('update-table')
        this.activateIsLoading = false
      },
      async removeEndpoint() {
        this.removeIsLoading = true
        try {
          await rtbEndpointsRepository.delete(this.endpoint.id)
        } catch (error) {
          this.$showErrorNotification(error.message)
        }
        this.$emit('update-table')
        this.removeIsLoading = false
      }
    }
  }
</script>
