<template>
  <v-chip v-bind="$attrs" class="justify-center custom-chip" :style="chipMinWidth" :color="withIcon ? 'secondary-lighten' : item.color">
    <div>
      <v-icon
        v-if="iconProps"
        left
        :size="16"
        :color="iconProps.iconColor ? iconProps.iconColor : ''"
      >
        {{ iconProps.icon }}
      </v-icon>
      <v-icon
        v-if="item.icon"
        left
        :size="16"
        :color="item.iconColor ? item.iconColor : ''"
      >
        {{ item.icon }}
      </v-icon>
      <span :class="[item.labelColor, 'text-truncate', { 'font-weight-medium ': withIcon }]">{{ $t(item.label) }}</span>
    </div>
  </v-chip>
</template>

<script>
  export default {
    name: 'CustomChipStatus',
    props: {
      value: {
        type: String,
        required: true
      },
      settings: {
        type: Array,
        default: () => []
      },
      withIcon: {
        type: Boolean,
        default: false
      },
      iconProps: {
        type: Object,
        default: () => ({})
      },
      minWidth: {
        type: Number,
        default: null
      },
      labelClass: {
        type: String,
        default: ''
      }
    },
    computed: {
      item() {
        const foundItem = this.settings.find((itemSettings) => itemSettings.value === this.value)
        return foundItem ?? { color: 'default', label: this.value }
      },
      chipMinWidth() {
        return this.minWidth ? `min-width: ${this.minWidth}px` : ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .custom-chip{
    padding: 0 8px;
  }

</style>
