<template>
  <v-dialog v-model="show" max-width="600">
    <v-card>
      <v-card-title>
        <slot name="title" :item="item" />
      </v-card-title>
      <v-card-text>
        <slot name="body" :item="item" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <c-btn text :label="$t('main.disagree')" @click="answer('cancel')" />
        <c-btn class="primary" depressed :label="$t('main.agree')" @click="answer('confirm')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'ConfirmDialog',
    components: {
      CBtn
    },
    data() {
      return {
        item: null,
        show: false
      }
    },
    methods: {
      ask(item) {
        this.show = true
        this.item = item
      },
      answer(answer) {
        this.$emit(answer, this.item)
        this.show = false
        this.item = null
      }
    }
  }
</script>
