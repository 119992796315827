<template>
  <v-card elevation="0">
    <v-card-title class="title d-flex align-center secondary pa-3">
      {{ $t('rtb.endpoints.rtb_endpoints') }}
      <v-spacer />
      <c-text-field
        :value="filters.name"
        append-icon="$magnifier"
        :placeholder="$t('rtb.endpoints.search')"
        background-color="white"
        hide-details
        @input="changeFilters('name', $event)"
      />
    </v-card-title>
    <v-divider />
    <v-data-table
      :headers="headers"
      :items="rtbEndpoints"
      :options.sync="options"
      :loading="rtbEndpointsIsLoading"
      hide-default-footer
      :server-items-length="rtbEndpointTotal"
      @page-count="pageCount = $event"
      @update:options="debouncedFetchRtbEndpoints()"
    >
      <template #item.status="{ item }">
        <custom-chip-status small :value="item.status" :settings="statuses" />
      </template>

      <template #item.endpoint="{ item }">
        <adaptive-string-table>
          <a :href="item.endpoint" target="_blank">
            {{ item.endpoint }}
          </a>
        </adaptive-string-table>
      </template>

      <template #item.actions="{ item }">
        <template v-if="!item.next_status">
          <rtb-actions :endpoint="item" @update-table="debouncedFetchRtbEndpoints()" />
        </template>
        <template v-else>
          {{ $t('rtb.endpoints.in_processing') }}
        </template>
      </template>
    </v-data-table>
    <v-divider />

    <data-table-footer
      :page-count="pageCount"
      :items-per-page="options.itemsPerPage"
      :current-page="options.page"
      @change-items-per-page="changePaginator($event, 'itemsPerPage')"
      @change-current-page="changePaginator($event, 'page')"
    />
  </v-card>
</template>

<script>
  import axios from 'axios'
  import debounce from 'debounce'
  import { datatableFormater } from '@clickadilla/datatable-formatter'
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import rtbEndpointsRepository from '@/services/repositories/rtb-endpoints-repository.js'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import RtbActions from '@/views/RtbEndpoints/RtbActions.vue'

  export default {
    name: 'RtbEndpoints',
    components: {
      RtbActions,
      CustomChipStatus,
      CTextField,
      DataTableFooter
    },
    data() {
      return {
        options: {
          sortBy: ['id'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15
        },
        filters: {
          name: ''
        },
        rtbEndpoints: [],
        debouncedFetchRtbEndpoints: null,
        rtbEndpointsIsLoading: false,
        statuses: [
          { value: 'active', label: 'rtb.endpoints.active', color: 'primary' },
          {
            value: 'needs_edits',
            label: 'rtb.endpoints.needs_edit',
            color: 'info-lighten white--text'
          },
          { value: 'paused', label: 'rtb.endpoints.paused', color: 'info' },
          { value: 'pending', label: 'rtb.endpoints.pending', color: 'warning white--text' }
        ],
        rtbEndpointTotal: 0,
        pageCount: 0
      }
    },
    computed: {
      headers() {
        return [
          { text: 'ID', value: 'id', width: 150 },
          { text: this.$t('rtb.endpoints.endpoint'), value: 'endpoint' },
          { text: this.$t('main.status'), value: 'status', width: 200 },
          {
            text: this.$t('main.actions'), value: 'actions', width: 150, sortable: false
          }
        ]
      }
    },
    created() {
      this.debouncedFetchRtbEndpoints = debounce(() => {
        this.fetchRtbEndpoints()
      }, 500)
    },
    methods: {
      changePaginator(val, key) {
        this.options[key] = val
        if (key === 'itemsPerPage') {
          this.options.page = 1
        }

        this.debouncedFetchRtbEndpoints()
      },
      changeFilters(key, value) {
        this.filters[key] = value
        this.options.page = 1
        this.debouncedFetchRtbEndpoints()
      },
      async fetchRtbEndpoints() {
        this.rtbEndpointsIsLoading = true
        const params = datatableFormater({
          headers: this.headers,
          search: this.filters,
          options: this.options
        })
        try {
          const response = await rtbEndpointsRepository.table(params)
          this.rtbEndpoints = response.data
          this.rtbEndpointTotal = response.recordsFiltered
        } catch (error) {
          if (!axios.isCancel(error)) {
            this.$showErrorNotification(error.message)
          }
        }
        this.rtbEndpointsIsLoading = false
      }
    }
  }
</script>
